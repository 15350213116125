import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import { Grid, MenuItem, Stack, TextField } from '@mui/material';
import Iconify from 'src/components/Iconify';
import palette from 'src/theme/palette';
import { Encryption } from 'src/functions/function';
import { api } from 'src/Api';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { LoadingButton } from '@mui/lab';
import { format } from 'date-fns';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { func } from 'prop-types';
import { functions } from 'lodash';

const style = {
  position: 'relative',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  maxHeight: '90%',
  overflowY: 'auto',
  bgcolor: 'background.paper',
  borderRadius: '8px',
  boxShadow: 24,
  p: 3
};

export default function PayslipModal({ handleClose, data }) {
  const { t } = useTranslation();

  /* --------------------FUNCTIONALITY--------------------- */
  const [payslipdata, setPaySlipData] = useState([]);
 // const [disabledbtn, setDisabledBtn] = useState(true);
  const [Handlededuction, sethandleDeduction] = useState([]);
  const [DeductionType, setDeductionType] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const [payName, setPayname] = useState([]);
  const [payType, setPaytype] = useState('');
  const [time, setTime] = useState('');
  const [amount, setAmount] = useState('0');
  const [quantity, setQuantity] = useState('1');
  const [loading, setloading] = useState(false);
  const [invoicePdf, setInPFD] = useState();
  /* =========== Amount Collection ========= */
  const [getDeduction, setGetDeduction] = useState('');
  /* ======------------------------------======== */
  // Validation error states
  const [payTypeValidationError, setPayTypeValidationError] = useState('');
  const [timeValidationError, setTimeValidationError] = useState('');
  const [amountValidationError, setAmountValidationError] = useState('');
  const [quantityValidationError, setQuantityValidationError] = useState('');
  const [isFormValid, setIsFormValid] = useState(true);
  const [month, setMonth] = useState(format(new Date(), 'yyyy-MM-dd'));

  const handleValidation = () => {
    let isValid = true;

    // Validation logic for Pay Type
    if (!payType) {
      setPayTypeValidationError('Payment Type is required');
      isValid = false;
    } else {
      setPayTypeValidationError('');
    }

    // Validation logic for Time

    if (!time) {
      setTimeValidationError('Cut off option is required');
      isValid = false;
    } else {
      setTimeValidationError('');
    }

    // Validation logic for Amount
    if (!amount) {
      setAmountValidationError('Amount is required');
      isValid = false;
    } else {
      setAmountValidationError('');
    }

    // Validation logic for Quantity
    if (!quantity) {
      setQuantityValidationError('Quantity is required');
      isValid = false;
    } else {
      setQuantityValidationError('');
    }

    setIsFormValid(isValid);

    return isValid;
  };

  useEffect(() => {
    if (payType) {
      handleValidation();
    }
  }, [payType, amount, time, quantity]);

  /* ======------------------------------======== */
  const handleInputChange = (index, key, value) => {
    const updatedDeductions = [...Handlededuction];
    updatedDeductions[index][key] = value; //
    sethandleDeduction(updatedDeductions);
    if (key == 'name') {
      updatedDeductions[index][key] = value; //
      const CheckdeductData = DeductionType.filter((x) => x.name == value);
      setGetDeduction(CheckdeductData[0]?.price);
      updatedDeductions[index]['amount'] = CheckdeductData[0]?.price;
    }
    if (key == 'amount') {
      setGetDeduction(value);
    }
  };
  let paycalculate = amount * quantity;
  let deductCalculate = Handlededuction.reduce((acc, item) => acc + item.amount * item.quantity, 0);
  let result = paycalculate - deductCalculate;

  const handleDeleteInput = (index) => {
    const newArray = [...Handlededuction];
    newArray.splice(index, 1);
    sethandleDeduction(newArray);
  };

  const handleAddInput = () => {
    sethandleDeduction([
      ...Handlededuction,
      { type: 'Deduction', name: '', amount: '', quantity: '1' }
    ]);
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////////
 // function hasEmptyKey()  {
  //  if (amount == '' || quantity == '') return true;

   /// if (payslipdata.length == 0) setDisabledBtn(true);

   // setDisabledBtn(false);
  //};
  ///////////////////////////////////////////////////////////////////////////////////////////////////////
  function UserPayType() {
    
    let paymentDetails = data?.payment_details
    setPaytype(paymentDetails.payment_code);
    setPaymentType(paymentDetails);


    if(paymentDetails.payment_code !== 'inventory'){
      const salary = data?.earnings;
      setAmount(salary);
      // setAmount(filteredByName?.price);
    }

    // setPaytype(paymentDetails.type);
    // const myHeaders = new Headers();
    // const token = JSON.parse(localStorage.getItem('token'));

    // myHeaders.append('Authorization', `Bearer ${token}`);
    // console.log('employee==', data);
    // let formdata = new FormData();
    // formdata.append('user_id', Encryption(data.id, process.env.REACT_APP_SECRET_APP));

    // let requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: 'follow'
    // };

    // fetch(api.get_payment_type_only, requestOptions)
    //   .then((response) => response.json())
    //   .then((result) => {
    //     console.log(result.data, 'result type only');
    //     if (result && result.success === true && result.data && result.data.length > 0) {
    //       setPaymentType(result.data);
    //       setPaytype(result.data[0].type);
    //     } else {
    //       console.error('API call failed:', result.error);
    //     }
    //   })
    //   .catch((error) => console.log('error', error))
    //   .finally(() => {});
  }


  function getPlayslipData()
  {
    setPaySlipData([]);
    
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    formdata.append('user_id', Encryption(data.id, process.env.REACT_APP_SECRET_APP));
    formdata.append('type', Encryption('in', process.env.REACT_APP_SECRET_APP));
    formdata.append('from_last_invoice', Encryption(true, process.env.REACT_APP_SECRET_APP));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_inventory, requestOptions)
      .then((response) => response.json())
      .then((result) => {

        if (result && result.success === true && result.data && result.data.length > 0) {
          setPaySlipData(result.data);
         
          console.log(payslipdata);
        } else {
          console.error('API call failed:', result.error);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {});

  }

  useEffect(() => {
    getPlayslipData()
    UserPayType();
    GetDeduction();
  }, []);

  useEffect(() => {
    let payCalculate = payslipdata.reduce((acc, item) => acc + item.price * item.quantity, 0);
    let deductCalculate = Handlededuction.reduce((acc, item) => acc + item.amount * item.quantity, 0);
    setAmount(payCalculate - deductCalculate);
  }, [payslipdata]);

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  function GetDeduction() {
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('user_id', Encryption(data?.id, process.env.REACT_APP_SECRET_APP));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.get_deductions, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success === true) {
          setDeductionType(result.data);
          console.log(DeductionType, 'check DeductionType');
        } else {
          console.error('API call failed:', result.error);
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {});
  }

  ///////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (payType) {
      setPayname([]);
      setTime('');
      setAmount(0);
      console.log(payType);

      
      if(payType !== 'inventory'){
        const salary = data?.earnings;
        setAmount(salary);
        setPayname(paymentType);
        setTime(paymentType?.name);
        // setAmount(filteredByName?.price);
      }
    }
  }, [payType]);

  useEffect(() => {
    console.log(' time useEffect');

    let paymentDetails = data?.payment_details;
    setPayname(paymentDetails.name);
    setTime(paymentDetails.name);
    
    if(paymentDetails.payment_code !== 'inventory'){
      const salary = data?.earnings;
      setAmount(salary);
    }
  
  }, [time]);


  ///////////////////////// Download PDF ///////////////////////////
  ///// Invoice PDF
  function Down_PDF(id) {
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('invoice_id', Encryption(id, process.env.REACT_APP_SECRET_APP));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.invoice_pdf, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        handleClose();
      })
      .catch((error) => console.log('error', error))
      .finally(() => {});
  }

  //////////////////////////////////////////////////////////////////////////////////////////////////////////


  function GetInvoice() {

    const mappedHandlepay = 
      (payType === 'inventory')
        ?  payslipdata.map(item => {
            return {
                type: item.product_id.name,
                name: item.product_id.name,
                quantity: item.quantity,
                amount: item.price
            };
          })
        : [{
            type: paymentType.type,
            name: paymentType.name,
            quantity: 1,
            amount: data?.earnings
          }];


    const mappedHandlededuction = Handlededuction.map(item => {
        return {
            type: "Deduction",
            name: item.name,
            quantity: item.quantity,
            amount: item.amount
        };
    });


    const invoice_details =  [
      ...mappedHandlepay,
      ...mappedHandlededuction
    ];

    console.log('for generation');
    console.log(month);
    let myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', 'Bearer ' + token);
    let formdata = new FormData();

    formdata.append('month', month);
    formdata.append('employee_id', data?.id);
    formdata.append('invoice_details', JSON.stringify(invoice_details));

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);

    fetch(api.invoice, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        console.log(result_, 'result_ Invoice User');
        setloading(false);
        const result = JSON.parse(result_);
        console.log(result, 'result entries');
        console.log(result.data, 'result Invoice entries');
        if (result.success === true) {
          console.log('result entries Success');
          Down_PDF(result?.data?.id);
        } else {
          console.log('error Invoice List Condition');
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error Invoice List', error);
      })
      .finally(() => setloading(false));
  }

  /////////////////////////////////////////////////////////////////

  const handleDateChange = (selectedDate) => {
    if (selectedDate) {
      const formattedDate = selectedDate.format('YYYY-MM-DD');
      console.log(formattedDate);
      setMonth(formattedDate);
    }
  };

  return (
    <>
      <Modal
        open={true}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
          <Stack>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={['DatePicker', 'DatePicker', 'DatePicker']}>
                <DatePicker
                  label={'Month and Year'}
                  views={['month', 'year']}
                  defaultValue={dayjs()} // Ensure this is providing the initial value properly
                  onChange={(e) => handleDateChange(e)} // Assuming the datepicker library returns a moment or dayjs object
                />
              </DemoContainer>
            </LocalizationProvider>
          </Stack>
          <Stack>

          {payslipdata.length > 0 ? (
            <>
              <Typography style={{ margin: '30px 0 30px 10px' }} >
                  Pay
              </Typography>

              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={3}>
                      <Typography
                          style={{
                              margin: '10px 0 10px 10px',
                              fontSize: 16,
                              textAlign: 'justify'
                          }}
                      >
                          Product
                      </Typography>
                  </Grid>
                  <Grid item xs={3}>
                      <Typography
                          style={{
                              margin: '10px 0 10px 10px',
                              fontSize: 16,
                              textAlign: 'justify'
                          }}
                      >
                          Unit price
                      </Typography>
                  </Grid>
                  <Grid item xs={3}>
                      <Typography
                          style={{
                              margin: '10px 0 10px 10px',
                              fontSize: 16,
                              textAlign: 'justify'
                          }}
                      >
                          Item Packed
                      </Typography>
                  </Grid>
                  <Grid item xs={3}>
                      <Typography
                          style={{
                              margin: '10px 0 10px 10px',
                              fontSize: 16,
                              textAlign: 'justify'
                          }}
                      >
                          Sub Total
                      </Typography>
                  </Grid>
              </Grid>

              {payslipdata.map((x, index) => (
                  <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} key={index}>
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          margin: '10px 0 10px 10px',
                          fontSize: 14,
                          textAlign: 'justify'
                        }}
                      >
                        {x.product_id.name}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          margin: '10px 0 10px 10px',
                          fontSize: 14,
                          textAlign: 'justify'
                        }}
                      >
                        {x.price}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                          style={{
                            margin: '10px 0 10px 10px',
                            fontSize: 14,
                            textAlign: 'justify'
                          }}
                        >
                          {x.quantity}
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography
                        style={{
                          margin: '10px 0 10px 10px',
                          fontSize: 14,
                          textAlign: 'justify'
                        }}
                      >
                        {x.quantity * x.price}
                      </Typography>
                    </Grid>
                  </Grid>
              ))}
            </>
            ) : payType !== 'inventory' ?
            (
              <>
              <Typography style={{ margin: '30px 0 30px 10px' }} >
                Payroll Period
              </Typography>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item xs={3}>
                      <Typography
                          style={{
                              margin: '10px 0 10px 10px',
                              fontSize: 16,
                              textAlign: 'justify'
                          }}
                      >
                          Basic Pay
                      </Typography>
                  </Grid>
                  <Grid item xs={3}>
                      <Typography
                          style={{
                              margin: '10px 0 10px 10px',
                              fontSize: 16,
                              textAlign: 'justify'
                          }}
                      >
                          Payment Name
                      </Typography>
                  </Grid>
                  <Grid item xs={3}>
                      <Typography
                          style={{
                              margin: '10px 0 10px 10px',
                              fontSize: 16,
                              textAlign: 'justify'
                          }}
                      >
                          Payment Type
                      </Typography>
                  </Grid>
                  <Grid item xs={3}>
                      <Typography
                          style={{
                              margin: '10px 0 10px 10px',
                              fontSize: 16,
                              textAlign: 'justify'
                          }}
                      >
                         Monthly Sub Total
                      </Typography>
                  </Grid>
              </Grid>

              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          margin: '10px 0 10px 10px',
                          fontSize: 14,
                          textAlign: 'justify'
                        }}
                      >
                       { data?.salary }
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                        style={{
                          margin: '10px 0 10px 10px',
                          fontSize: 14,
                          textAlign: 'justify'
                        }}
                      >
                        { paymentType.name }
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography
                          style={{
                            margin: '10px 0 10px 10px',
                            fontSize: 14,
                            textAlign: 'justify'
                          }}
                        >
                          { paymentType.type }
                         
                      </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography
                        style={{
                          margin: '10px 0 10px 10px',
                          fontSize: 14,
                          textAlign: 'justify'
                        }}
                      >
                        { data?.earnings }
                      </Typography>
                    </Grid>
                  </Grid>
                  
              </>
            ) :
            (
              <>
              <Typography
                style={{
                    textAlign: 'center',
                    marginTop: '50px',
                    fontSize: 18
                }}
                >
                No Inventory Items Found Since Last Invoice
              </Typography>
              </>
            )}


            <div /*style={{ maxHeight: '400px' }} */>
              <Stack>
              {Handlededuction.length > 0 && (
               <> 
              
                <Typography
                  style={{
                    margin: '30px 0 30px 10px'
                  }}
                >
                  Deductions (Optional)
                </Typography>
                {Handlededuction.map((x, index) => (
                  <Grid
                    container
                    rowSpacing={1}
                    columnSpacing={{ xs: 1, sm: 2, md: 1 }}
                    sx={{ mt: 1 }}
                  >
                    <Grid item xs={3.66}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        select
                        label="Category"
                        value={x.name}
                        onChange={(e) => handleInputChange(index, 'name', e.target.value)}
                      >
                        {DeductionType?.map((type) => (
                          <MenuItem key={type.id} value={type.name}>
                            {type?.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={3.66}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        type="number"
                        label="Amount To Be Deducted"
                        value={x.amount}
                        onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3.66}>
                      <TextField
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                        type="number"
                        label="No Of Deductions"
                        value={x.quantity}
                        onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        onClick={handleDeleteInput}
                        style={{ height: 56, fontSize: 24, borderColor: 'black' }}
                        variant="outlined"
                        to="#"
                      >
                        <Iconify color={palette.primary.main} icon="iconoir:cancel" />
                      </Button>
                    </Grid>
                  </Grid>
                ))}
                </>
                )}

                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                  <Grid item>
                   
                      <Button
                        style={{
                          width: 200,
                          marginTop: 25,
                          marginBottom: 15,
                          backgroundColor: 'black',
                          padding: 12
                        }}
                        onClick={handleAddInput}
                        variant="contained"
                        to="#"
                        
                        startIcon={<Iconify icon="eva:plus-fill" />}
                      >
                        Add Deduction
                      </Button>
                  
                  </Grid>
                </Grid>
              </Stack>
            </div>
          </Stack>
          <Box
            pt={2}
            pb={2}
            style={{
              backgroundColor: 'white',
              position: 'sticky',
              bottom: -35,
              // borderBottom: '2px solid',
              zIndex: 5,
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'space-between',
              marginLeft: -10
            }}
          >
            <Stack>
              {amount ? (
                <Stack
                  style={{
                    paddingLeft: 10,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between'
                  }}
                >
                  <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography style={{ fontWeight: 600 }}>Total Pay:</Typography>
                    <Typography style={{ marginLeft: 2 }}>
                      {amount ? paycalculate : 'N/A'}
                    </Typography>
                  </Stack>
                  <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography style={{ fontWeight: 600 }}>Total Deductions:</Typography>
                    <Typography style={{ marginLeft: 2 }}>
                      {Handlededuction.length > 0 ? `-${getDeduction ? deductCalculate : 0}` : 0}
                    </Typography>
                  </Stack>
                  <Stack style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Typography style={{ fontWeight: 600 }}>Total:</Typography>
                    <Typography style={{ marginLeft: 2 }}>{result}</Typography>
                  </Stack>
                </Stack>
              ) : null}
            </Stack>
            <Stack
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
     {/* {payslipdata.length > 0 ? ( */}
    <>
        <LoadingButton
            sx={{ marginRight: 2 }}
            size="large"
          
            onClick={() => GetInvoice()}
            variant="contained"
            loading={loading}
        >
            Generate Pay Slip
        </LoadingButton>
    </>
    {/* ) : null} */}

           
              <Box>
                <Button
                  onClick={handleClose}
                  style={{ paddingTop: 11, paddingBottom: 11 }}
                  variant="outlined"
                >
                  Cancel
                </Button>
              </Box>
            </Stack>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
