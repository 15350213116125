import * as React from 'react';
// import Box from '@mui/materia'
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Modal,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Pagination,
  Box
} from '@mui/material';
import moment from 'moment';

// components

import Page from 'src/components/Page';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import Iconify from 'src/components/Iconify';
import SearchNotFound from 'src/components/SearchNotFound';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { UserListHead } from 'src/sections/@dashboard/user';
import pallete from 'src/theme/palette';
import { api } from 'src/Api';
import { Decryption, Encryption } from 'src/functions/function';
import PaymentTypeModal from 'src/sections/@dashboard/user/PaymentTypeModal';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function SettingTab1() {
  const [TABLE_HEAD, setTABLE_HEAD] = useState([
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false },
    { id: 'price', label: 'Price', alignRight: false }
  ]);

  const [permission, setpermission] = useState([]);
  const [showGridActons, setShowGridActons] = useState(false);

  const requirement = ['View', 'Add', 'Edit', 'Delete'];

  function checkRender() {
    let check = 'Settings';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name == check
    );
    console.log(localPerm);
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name)
        ? setpermission((permissions) => [...permissions, items.permission_type_name])
        : false
    );

    ////////////////////  for grid actons ////////////////////

    const requirementGrid = ['Edit', 'Delete', 'Generate', 'Payment History'];

    const checkGridActions = localPerm[0]?.permission_types?.filter((items) =>
      requirementGrid.includes(items.permission_type_name) ? true : false
    );

    if (checkGridActions && checkGridActions.length > 0) {
      setShowGridActons(true);
      setTABLE_HEAD((prevState) => [
        ...prevState,
        { id: 'actions', label: 'Actions', alignCenter: true }
      ]);
    } else {
      setShowGridActons(false);
    }

    ////////////////////////////////////////////////////////////
  }
  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');

  const [deductionModal, setPaymentTypeModal] = useState(false);
  const handlePaymentTypeModalClose = () => setPaymentTypeModal(false);

  const refreshpaymentType = () => {
    // setPaymentTypeModal(false);
    setPaymentTypeModal(false);
    getPaymentType();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  function getPaymentType(page) {
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    // console.log(token);
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_APP));
    search?.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.getPaymentType, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          list.length = 0;
          setlist(result.data);
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
        setloadingTable(false);
      });
  }

  const [selectedIndex, setselectedIndex] = useState(null);
  const [deleting, setdeleting] = useState(false);

  function DeletePaymentType(id, index) {
    setselectedIndex(index);
    setdeleting(true);
    const token = JSON.parse(localStorage.getItem('token'));
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('id', Encryption(id, process.env.REACT_APP_SECRET_APP));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    console.log('before deleting');
    fetch(`${api.DeletePaymentType}`, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          // window.alert(id, 'deleted');
          console.log('Successfully deleted');
          getPaymentType(page);
        }
      })
      .catch((error) => {
        console.log('error', error, 'Catch deleted error');
      })
      .finally(() => {
        setdeleting(false);
        setselectedIndex(null);
      });
  }

  useEffect(() => {
    setloading(true);
    checkRender();
    getPaymentType();
  }, []);
  const isUserNotFound = list.length === 0;

  const [loading, setloading] = useState(false);
  const [loadingTable, setloadingTable] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getPaymentType(value);
  };

  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      setloadingTable(true);
      getPaymentType();
    }
  };

  const [deduction, setdeduction] = useState(null);

  return (
    <Page title={`Payment Types | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* INVENTORY MODAL */}
        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={deductionModal}
          onClose={handlePaymentTypeModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="admin_modal" borderRadius={2}>
            <PaymentTypeModal
              handleClose={handlePaymentTypeModalClose}
              refresh={refreshpaymentType}
              data={deduction}
            />
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Payment Type
          </Typography>

          {permission.includes('Add') && (
            <>
              <Button
                onClick={() => {
                  setdeduction(null);
                  setPaymentTypeModal(true);
                }}
                variant="contained"
                component={RouterLink}
                to="#"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                Add Payment Type
                {/* Add User */}
              </Button>
            </>
          )}
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
          >
            <Searchbar
              placeholder="Search Payment Types"
              width={'95%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />

            <LoadingButton
              style={{ width: '5%', marginLeft: '2.5%' }}
              variant="contained"
              loading={loadingTable}
              onClick={() => {
                setloadingTable(true);
                getPaymentType();
              }}
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Box>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                  <TableBody>
                    {list.map((row, index) => {
                      const { id, name, type, price, role } = row;

                      return (
                        <TableRow
                          hover
                          style={{
                            cursor: 'pointer'
                            // Remove fixed height style
                          }}
                          key={id}
                          role="checkbox"
                        >
                          <TableCell component="th" scope="row" padding="normal">
                            {name && name ? name : 'N/A'}
                          </TableCell>

                          <TableCell component="th" scope="row" padding="normal">
                            {type && type ? type : 'N/A'}
                          </TableCell>

                          <TableCell>
                            {' '}
                            {/* Set align to "center" */}
                            {price || 'N/A'}
                          </TableCell>
                          {showGridActons && (
                            <>
                              <TableCell align="center" style={{ flexDirection: 'column' }}>
                                {' '}
                                {/* Set align to "center" */}
                                {permission.includes('Edit') && (
                                  <>
                                    <Button
                                      onClick={(event) => {
                                        // Down_PDF(id);
                                        setdeduction(row);
                                        setPaymentTypeModal(true);
                                      }}
                                      style={{ marginTop: 5 }}
                                      variant="outlined"
                                      component={RouterLink}
                                      to="#"
                                      startIcon={
                                        <Iconify color={pallete.primary.main} icon="tabler:edit" />
                                      }
                                    >
                                      Edit
                                    </Button>
                                  </>
                                )}
                                {permission.includes('Delete') && (
                                  <>
                                    <LoadingButton
                                      loading={deleting && selectedIndex == index && deleting}
                                      onClick={(event) => {
                                        DeletePaymentType(id, index);
                                        // setdeduction(row);
                                        // setPaymentTypeModal(true);
                                      }}
                                      style={{ marginTop: 5, marginLeft: 10 }}
                                      variant="outlined"
                                      component={RouterLink}
                                      to="#"
                                      startIcon={
                                        <Iconify
                                          color={pallete.primary.main}
                                          icon="ic:baseline-delete"
                                        />
                                      }
                                    >
                                      Delete
                                    </LoadingButton>
                                  </>
                                )}
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isUserNotFound && !loading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={search} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                {loading && (
                  <Box
                    py={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flex: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </TableContainer>
            </Scrollbar>
          </Box>
        </Card>
        {!loading && !loadingTable && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
            p={5}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
