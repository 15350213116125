import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Pagination
} from '@mui/material';
import moment from 'moment';

// components

import Page from 'src/components/Page';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import Iconify from 'src/components/Iconify';
import SearchNotFound from 'src/components/SearchNotFound';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import { UserListHead } from 'src/sections/@dashboard/user';
import pallete from 'src/theme/palette';
import { api } from 'src/Api';
import { Decryption, Encryption } from 'src/functions/function';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function PaymentHistoryModal({ data }) {
  const { t } = useTranslation();
  const TABLE_HEAD = [
    { id: 'invoice_no', label: 'Invoice No', alignRight: false },
    { id: 'month', label: 'Month', alignRight: false },
    { id: 'total', label: 'Total', alignRight: false },
    { id: 'dedcution', label: 'Deduction', alignRight: false },
    { id: 'grandtotal', label: 'Grand Total', alignRight: false },
    { id: 'action', label: t('Agents.table.4'), alignCenter: true }
  ];

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');

  function getInvoice(page) {
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    // console.log(token);
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_APP));
    search?.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));
    formdata.append('employee_id', Encryption(data.id, process.env.REACT_APP_SECRET_APP));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_invoice, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          list.length = 0;
          setlist(result.data);
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
        setloadingTable(false);
      });
  }

  useEffect(() => {
    getInvoice();
  }, []);

  useEffect(() => {
    setloading(true);
    getInvoice();
  }, []);
  const isUserNotFound = list.length === 0;

  const [loading, setloading] = useState(false);
  const [loadingTable, setloadingTable] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getInvoice(value);
  };

  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      setloadingTable(true);
      getInvoice();
    }
  };

  function Down_PDF(id) {
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('invoice_id', Encryption(id, process.env.REACT_APP_SECRET_APP));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.invoice_pdf, requestOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        window.open(url);
        // handleClose();
      })
      .catch((error) => console.log('error', error))
      .finally(() => {});
  }

  return (
    <Page title={`Employees | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
          >
            <Searchbar
              placeholder={t('Agents.search') + '...'}
              width={'95%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />

            <LoadingButton
              style={{ width: '5%', marginLeft: '2.5%' }}
              variant="contained"
              loading={loadingTable}
              onClick={() => {
                setloadingTable(true);
                getInvoice();
              }}
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Box>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                  <TableBody>
                    {list.map((row) => {
                      const {
                        id,
                        invoice_no,
                        month,
                        total_salary,
                        total_deduction,
                        grand_total,
                        role
                      } = row;

                      return (
                        <TableRow
                          hover
                          style={{
                            cursor: 'pointer'
                            // Remove fixed height style
                          }}
                          key={id}
                          role="checkbox"
                        >
                          <TableCell component="th" scope="row" padding="normal">
                            {invoice_no}
                          </TableCell>

                          <TableCell>
                            {' '}
                            {/* Set align to "center" */}
                            {month ? moment(month).format('MMM YYYY') : 'N/A'}{' '}
                          </TableCell>

                          <TableCell>
                            {' '}
                            {/* Set align to "center" */}
                            {total_salary || 'N/A'}
                          </TableCell>

                          <TableCell>
                            {' '}
                            {/* Set align to "center" */}
                            {total_deduction || 'N/A'}
                          </TableCell>

                          <TableCell key={role?.id}>
                            {' '}
                            {/* Set align to "center" */}
                            {grand_total || 'N/A'}
                          </TableCell>

                          <TableCell align="center" style={{ flexDirection: 'column' }}>
                            {' '}
                            {/* Set align to "center" */}
                            <Button
                              onClick={(event) => {
                                Down_PDF(id);
                              }}
                              style={{ marginTop: 5 }}
                              variant="outlined"
                              component={RouterLink}
                              to="#"
                              startIcon={
                                <Iconify
                                  color={pallete.primary.main}
                                  icon="fluent:payment-20-filled"
                                />
                              }
                            >
                              Download
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isUserNotFound && !loading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={search} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                {loading && (
                  <Box
                    py={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flex: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </TableContainer>
            </Scrollbar>
          </Box>
        </Card>
        {!loading && !loadingTable && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
            p={5}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
