import * as React from 'react';
// import Box from '@mui/materia'
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Modal,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  CircularProgress,
  Pagination,
  Box
} from '@mui/material';
import moment from 'moment';

// components

import Page from 'src/components/Page';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import Iconify from 'src/components/Iconify';
import SearchNotFound from 'src/components/SearchNotFound';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import InventoryAddModal from 'src/sections/@dashboard/user/InventoryAddModal';
import { UserListHead } from 'src/sections/@dashboard/user';
import pallete from 'src/theme/palette';
import { api } from 'src/Api';
import { Decryption, Encryption } from 'src/functions/function';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function PaymentHistoryModal() {

  const TABLE_HEAD = [
    { id: 'product_id', label: 'Product', alignRight: false },
    { id: 'employee_id', label: 'Employee', alignRight: false },
    { id: 'quantity', label: 'Quantity', alignRight: false },
    { id: 'type', label: 'Type', alignRight: false }
    // { id: 'price', label: 'Price', alignRight: false }
    // { id: 'action', label: t('Agents.table.4'), alignCenter: true }
  ];
  const [permission, setpermission] = useState([]);
  const requirement = [
    'Add',
  ];

  function checkRender() {
    let check = 'Inventory';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name == check
    );
    console.log(localPerm)
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name)
        ? setpermission((permissions) => [...permissions, items.permission_type_name])
        : false
    );
  }
  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');

  const [inventoryModal, setInventoryModal] = useState(false);
  const handleInventoryModalClose = () => setInventoryModal(false);

  const refreshInventory = () => {
    // setInventoryModal(false);
    setInventoryModal(false);
    getInvoice();
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  function getInvoice(page) {
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    // console.log(token);
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();

    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_APP));
    search?.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_inventory, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);

        if (result.success === true) {
          list.length = 0;
          setlist(result.data);
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
        setloadingTable(false);
      });
  }


  useEffect(() => {
    setloading(true);
    checkRender();
    getInvoice();
  }, []);
  const isUserNotFound = list.length === 0;

  const [loading, setloading] = useState(false);
  const [loadingTable, setloadingTable] = useState(false);
  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getInvoice(value);
  };

  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      setloadingTable(true);
      getInvoice();
    }
  };

  return (
    <Page title={`Employees | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* INVENTORY MODAL */}
        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={inventoryModal}
          onClose={handleInventoryModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="admin_modal" borderRadius={2}>
            <InventoryAddModal
              handleClose={handleInventoryModalClose}
              refresh={refreshInventory}
              // data={user}
            />
          </Box>
        </Modal>

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Inventory
          </Typography>

          {permission.includes('Add') && (
                               <>
          <Button
            onClick={() => {
              console.log('it works!');
              // setuser(null);
              setInventoryModal(true);
            }}
            variant="contained"
            component={RouterLink}
            to="#"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Inventory IN + OUT
            {/* Add inventory */}
          </Button>
          </>
          )}
        </Stack>

        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
          >
            <Searchbar
              placeholder='Search Product...'
              width={'95%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />

            <LoadingButton
              style={{ width: '5%', marginLeft: '2.5%' }}
              variant="contained"
              loading={loadingTable}
              onClick={() => {
                setloadingTable(true);
                getInvoice();
              }}
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Box>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <UserListHead headLabel={TABLE_HEAD} rowCount={list.length} />
                  <TableBody>
                    {list.map((row) => {
                      const { id, product_id, employee_id, quantity, type, user, price, role } = row;

                      return (
                        <TableRow
                          hover
                          style={{
                            cursor: 'pointer'
                            // Remove fixed height style
                          }}
                          key={id}
                          role="checkbox"
                        >
                          <TableCell component="th" scope="row" padding="normal">
                            {product_id && product_id.name ? product_id.name : 'N/A'}
                          </TableCell>

                          <TableCell component="th" scope="row" padding="normal">
                            {employee_id && employee_id.fullname ? employee_id.fullname : 'N/A'}
                          </TableCell>
                          <TableCell>
                            {' '}
                            {/* Set align to "center" */}
                            {quantity || 'N/A'}
                          </TableCell>

                          <TableCell>
                            {' '}
                            {/* Set align to "center" */}
                            {type || 'N/A'}
                          </TableCell>

                       
                        </TableRow>
                      );
                    })}
                  </TableBody>
                  {isUserNotFound && !loading && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={search} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
                {loading && (
                  <Box
                    py={5}
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      flex: 1
                    }}
                  >
                    <CircularProgress />
                  </Box>
                )}
              </TableContainer>
            </Scrollbar>
          </Box>
        </Card>
        {!loading && !loadingTable && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
            p={5}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
