import { Navigate, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
import Login from './pages/Login';
import Businesses from './pages/Products';
import Admin from './pages/Employee';
import Rolepermission from './pages/Rolepermission';
import NotFound from './pages/Page404';
import Requests from './pages/Settings';
import Documents from './pages/Inventory';


// ----------------------------------------------------------------------

export default function Router() {
  let localPerm = JSON.parse(localStorage.getItem('permissions'));

  return useRoutes([
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '/', element: <Navigate to="/login" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        

        {
          path: 'products',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'products')?.length >
            0 ? (
              <Businesses />
            ) : (
              <Navigate to="/404" />
            )
        },

        {
          path: 'roles',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'role/permission')
              ?.length > 0 ? (
              <Rolepermission />
            ) : (
              <Navigate to="/404" />
            )
        },
       
        
  
        {
          path: 'employees',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'employees')?.length >
            0 ? (
              <Admin />
            ) : (
              <Navigate to="/404" />
            )
        },
     
        

        {
          path: 'settings',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'settings')?.length >
            0 ? (
              <Requests />
            ) : (
              <Navigate to="/404" />
            )
        },

    
        {
          path: 'inventory',
          element:
            localPerm?.filter((item) => item.permission_name.toLowerCase() == 'inventory')?.length >
            0 ? (
              <Documents />
            ) : (
              <Navigate to="/404" />
            )
        }
       

      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
