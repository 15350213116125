import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
// material
import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Box,
  Checkbox,
  Typography,
  Alert,
  MenuItem
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import { api } from 'src/Api';
import { Encryption } from 'src/functions/function';
import Iconify from 'src/components/Iconify';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function AdminEditModal({ handleClose, refresh, data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [checkBox, setcheckBox] = useState(false);
  const [checkerror, setcheckerror] = useState(false);
  const [loading, setloading] = useState(false);
  const [userData, setuserData] = useState(data);
  const [roles, setRoles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);



  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string().required(t('AddAgent.required.0')),
    lastName: Yup.string().required(t('AddAgent.required.1')),
    email: Yup.string().email(t('AddAgent.required.5')).required(t('AddAgent.required.2')),
    username: Yup.string().required(t('username required')),
    password: Yup.string().required(t('AddAgent.required.3')),
    confirmpassword: Yup.string().required(t('AddAgent.required.4')),
    role: Yup.number().required(t('AddAgent.required.7 required')),
    position: Yup.string().required(t('AddAgent.required.8 required')),
    dateHired: Yup.date().required(t('AddAgent.required.9 required')),
    dateResigned: Yup.date(),
    attachment: Yup.date()
  });


  const [permission, setpermission] = useState([]);
  const requirement = [

    'Change Role'
  ];

  function checkRender() {
    let check = 'Employees';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name == check
    );
    console.log(localPerm)
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name)
        ? setpermission((permissions) => [...permissions, items.permission_type_name])
        : false
    );
  }

  useEffect(() => {
    checkRender()
  }, []);

  useEffect(() => {
    setuserData(data);
  }, [userData]);

  const formik = useFormik({
    initialValues: {
      firstName: data?.firstname,
      lastName: data?.lastname,
      email: data?.email,
      username: data?.username,
      password: '',
      confirmpassword: '',
      role: data?.role.id,
      position: data?.position,
      dateHired: data?.dateHired,
      dateResigned: data?.dateResigned,
      attachment: data?.attachment
    },
    validationSchema: RegisterSchema,
    onSubmit: () => EditAdmin()
  });
  useEffect(() => {
    console.log(data);
    if (checkBox === true) {
      setcheckerror(false);
    }
  }, [checkBox]);
  //  navigate('/dashboard', { replace: true });

  const [roleId, setroleId] = useState(null);
 

  function EditAdmin() {

    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', 'Bearer ' + token);
    var formdata = new FormData();
    formdata.append('id', Encryption(data.id, process.env.REACT_APP_SECRET_APP));
    formdata.append('role', Encryption(1, process.env.REACT_APP_SECRET_APP));
    formdata.append(
      'username',
      Encryption(formik.values.username, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append(
      'firstname',
      Encryption(formik.values.firstName, process.env.REACT_APP_SECRET_APP)
    );
    formdata.append(
      'lastname',
      Encryption(formik.values.lastName, process.env.REACT_APP_SECRET_APP)
    );
    formdata.append('email', Encryption(formik.values.email, process.env.REACT_APP_SECRET_APP));
    formdata.append(
      'password',
      Encryption(formik.values.password, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append(
      'confirm_password',
      Encryption(formik.values.confirmpassword, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append(
      'position',
      Encryption(formik.values.position, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append(
      'date_hired',
      Encryption(formik.values.dateHired, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append(
      'date_resigned',
      Encryption(formik.values.dateResigned, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append(
      'attachment',
      Encryption(formik.values.attachment, process.env.REACT_APP_SECRET_KEY)
    );
    formdata.append('company_id', Encryption(user.id, process.env.REACT_APP_SECRET_APP));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    setloading(true);
    fetch(api.update_profile, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        console.log(result_, 'result_ Edit User');
        setloading(false);
        const result = JSON.parse(result_);
        console.log(result, 'result');
        if (result.success === true) {
          refresh();
        } else {
          setalert({ visible: true, message: result.errors });
          console.log('error Edit Admin Condition');
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error Edit Admin', error);
      })
      .finally(() => setloading(false));
  }

  console.log('After Edit Admin');

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const [alert, setalert] = useState({ visible: false, message: '' });

  const handleDownload = () => {
    if (selectedFile && selectedFile instanceof File) {
      const downloadLink = document.createElement('a');
      const fileUrl = URL.createObjectURL(selectedFile);
      downloadLink.href = fileUrl;
      downloadLink.download = selectedFile.name;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    }
  };
  const passwordGenerator = () => {
    const result = (Math.random() + 1).toString(36).substring(0);
    formik.values.password = result;
    formik.values.confirmpassword = result;
    formik.validateField();
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 20 }}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={t('AddAgent.fields.0')}
              {...getFieldProps('firstName')}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />
            <TextField
              fullWidth
              label={t('AddAgent.fields.1')}
              {...getFieldProps('lastName')}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              autoComplete="username"
              type="email"
              label={t('AddAgent.fields.2')}
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              fullWidth
              label={t('username')}
              {...getFieldProps('username')}
              error={Boolean(touched.username && errors.username)}
              helperText={touched.username && errors.username}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
           
              fullWidth
              select
              label={t('AddAgent.fields.7')}
              {...getFieldProps('role')}
              SelectProps={{ native: false }}
              error={Boolean(touched.role && errors.role)}
              helperText={touched.role && errors.role}
            >
         
              {roles?.map((role) => (
                <MenuItem key={role.id} value={role.id}>
                  {role?.role_name}
                </MenuItem>
              ))}
            </TextField>

            <TextField
              fullWidth
              label={t('AddAgent.fields.8')}
              {...getFieldProps('position')}
              error={Boolean(touched.position && errors.position)}
              helperText={touched.position && errors.position}
            />
          </Stack>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="date"
              label={t('AddAgent.fields.9')}
              {...getFieldProps('dateHired')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                placeholder: 'YYYY-MM-DD' // Customize the placeholder text
              }}
              error={Boolean(touched.dateHired && errors.dateHired)}
              helperText={touched.dateHired && errors.dateHired}
            />
            <TextField
              fullWidth
              type="date"
              label={t('date Resigned')}
              {...getFieldProps('dateResigned')}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                placeholder: 'YYYY-MM-DD' // Customize the placeholder text
              }}
              error={Boolean(touched.dateResigned && errors.dateResigned)}
              helperText={touched.dateResigned && errors.dateResigned}
            />
          </Stack>
          <>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label={t('AddAgent.fields.3')}
                {...getFieldProps('password')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <TextField
                value={formik.values.confirmpassword}
                fullWidth
                type={showConfirmPassword ? 'text' : 'password'}
                label={t('AddAgent.fields.4')}
                {...getFieldProps('confirmpassword')}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setshowConfirmPassword((prev) => !prev)}
                      >
                        <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                error={Boolean(touched.confirmpassword && errors.confirmpassword)}
                helperText={touched.confirmpassword && errors.confirmpassword}
              />
            </Stack>
            {/* <TextField
              fullWidth
              type="file"
              label="Select File"
              onChange={(e) => setSelectedFile(e.target.files[0])}
            /> */}
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <Button
                style={{
                  width: '100%',
                  height: 50
                }}
                variant={selectedFile ? 'outlined' : 'outlined'}
                component="label"
              >
                {selectedFile?.name ? selectedFile?.name : 'Add Document'}
                <input
                  type="file"
                  hidden
                  {...getFieldProps('attachment')}
                  accept="file/*"
                  onChange={(e) => {
                    setSelectedFile(e.target.files[0]);
                    console.log(e.target.files[0].name, 'selectedFile');
                  }}
                />
              </Button>
              <Button
                style={{
                  width: '100%',
                  height: 50
                }}
                onClick={handleDownload}
                variant="outlined"
              >
                Download File
              </Button>
            </Stack>
            <Button
              sx={{
                marginRight: 1
              }}
              onClick={passwordGenerator}
              color="primary"
              variant="outlined"
            >
              {t('AddAgent.buttons.0')}
            </Button>
          </>
          {alert.visible && (
            <Alert
              onClose={() => {
                setalert({ visible: false, message: '' });
              }}
              severity="error"
              style={{ fontSize: 12, marginBottom: 20 }}
            >
              {Object.entries(alert?.message).map(([field, message]) => (
                <p key={field}>
                  <b>{field}:</b> {message}
                </p>
              ))}
            </Alert>
          )}
          {invoiceData && (
            <Typography variant="h1" gutterBottom>
              working Fine
              {/* Invoice Data: {JSON.stringify(invoiceData)} */}
            </Typography>
          )}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <LoadingButton
              sx={{ marginRight: 2 }}
              size="large"
              onClick={() => EditAdmin()}
              variant="contained"
              loading={loading}
            >
              {t('AddAgent.buttons.1')}
            </LoadingButton>
            <Button onClick={handleClose} variant="outlined">
              {t('AddAgent.buttons.2')}
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
    // <FormikProvider value={formik}>
    //   <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 20 }}>
    //     <Stack spacing={3}>
    //       <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
    //         <TextField
    //           fullWidth
    //           label="First name"
    //           {...getFieldProps('firstName')}
    //           error={Boolean(touched.firstName && errors.firstName)}
    //           helperText={touched.firstName && errors.firstName}
    //         />
    //         <TextField
    //           fullWidth
    //           label="Last name"
    //           {...getFieldProps('lastName')}
    //           error={Boolean(touched.lastName && errors.lastName)}
    //           helperText={touched.lastName && errors.lastName}
    //         />
    //       </Stack>
    //       <TextField
    //         fullWidth
    //         autoComplete="username"
    //         type="email"
    //         label="Email address"
    //         {...getFieldProps('email')}
    //         error={Boolean(touched.email && errors.email)}
    //         helperText={touched.email && errors.email}
    //       />

    //       {/* <DropDown
    //         dontAppend
    //         fullWidth
    //         setRole={(val) => {
    //           setroleId(val.id);
    //           setcheckerror(false);
    //         }}
    //         error={checkerror}
    //       /> */}

    //       <>
    //         <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
    //           <TextField
    //             fullWidth
    //             type={showPassword ? 'text' : 'password'}
    //             label={'passowrd'}
    //             {...getFieldProps('password')}
    //             InputProps={{
    //               endAdornment: (
    //                 <InputAdornment position="end">
    //                   <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
    //                     <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
    //                   </IconButton>
    //                 </InputAdornment>
    //               )
    //             }}
    //             error={Boolean(touched.password && errors.password)}
    //             helperText={touched.password && errors.password}
    //           />
    //           <TextField
    //             value={formik.values.confirmpassword}
    //             fullWidth
    //             type={showConfirmPassword ? 'text' : 'password'}
    //             label={'Confirm password'}
    //             {...getFieldProps('confirmpassword')}
    //             InputProps={{
    //               endAdornment: (
    //                 <InputAdornment position="end">
    //                   <IconButton
    //                     edge="end"
    //                     onClick={() => setshowConfirmPassword((prev) => !prev)}
    //                   >
    //                     <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
    //                   </IconButton>
    //                 </InputAdornment>
    //               )
    //             }}
    //             error={Boolean(touched.confirmpassword && errors.confirmpassword)}
    //             helperText={touched.confirmpassword && errors.confirmpassword}
    //           />
    //         </Stack>
    //       </>

    //       {/* <TextField
    //         fullWidth
    //         autoComplete="current-password"
    //         type={showPassword ? 'text' : 'password'}
    //         label="Password"
    //         {...getFieldProps('password')}
    //         InputProps={{
    //           endAdornment: (
    //             <InputAdornment position="end">
    //               <IconButton edge="end" onClick={() => setShowPassword((prev) => !prev)}>
    //                 <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
    //               </IconButton>
    //             </InputAdornment>
    //           )
    //         }}
    //         error={Boolean(touched.password && errors.password)}
    //         helperText={touched.password && errors.password}
    //       />
    //       <TextField
    //         value={formik.values.confirmpassword}
    //         fullWidth
    //         autoComplete="current-password"
    //         type={showConfirmPassword ? 'text' : 'password'}
    //         label="Confirm Password"
    //         {...getFieldProps('confirmpassword')}
    //         InputProps={{
    //           endAdornment: (
    //             <InputAdornment position="end">
    //               <IconButton edge="end" onClick={() => setshowConfirmPassword((prev) => !prev)}>
    //                 <Iconify icon={showConfirmPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
    //               </IconButton>
    //             </InputAdornment>
    //           )
    //         }}
    //         error={Boolean(touched.confirmpassword && errors.confirmpassword)}
    //         helperText={touched.confirmpassword && errors.confirmpassword}
    //       /> */}
    //       {/* <Button
    //         sx={{
    //           marginRight: 1
    //         }}
    //         onClick={passwordGenerator}
    //         color="primary"
    //         variant="outlined"
    //       >
    //         Auto Generate Password
    //       </Button> */}
    //       {alert.visible && (
    //         <>
    //           <Alert
    //             onClose={() => {
    //               setalert({ visible: false, message: '' });
    //             }}
    //             severity="error"
    //           >
    //             {Object.values(alert?.message)[0][0]}
    //             {/* {alert.message} */}
    //             {/* {Object.entries(result.errors).map(([key, value]) => (
    //               <ListItem key={key}>
    //                 <ListItemText primary={value[0]} />
    //               </ListItem>
    //             ))} */}
    //           </Alert>
    //         </>
    //       )}
    //       <Box
    //         style={{
    //           display: 'flex',
    //           flexDirection: 'row',
    //           justifyContent: 'flex-end',
    //           width: '100%'
    //         }}
    //       >
    //         <LoadingButton
    //           sx={{ marginRight: 2 }}
    //           size="large"
    //           onClick={() => EditAdmin()}
    //           variant="contained"
    //           loading={loading}
    //         >
    //           Save
    //         </LoadingButton>
    //         <Button onClick={handleClose} variant="outlined">
    //           Cancel
    //         </Button>
    //       </Box>
    //     </Stack>
    //   </Form>
    // </FormikProvider>
  );
}
