import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useRouteLoaderData, redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// material
import {
  Card,
  Stack,
  Button,
  Container,
  Typography,
  Modal,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CircularProgress
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

// components
import Page from '../components/Page';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import { RoleModal } from '../sections/@dashboard/role';
import { api } from '../Api';
import palette from 'src/theme/palette';
import { Decryption, Encryption } from 'src/functions/function';
import { func } from 'prop-types';

export default function Rolepermission() {
  const [permission, setpermission] = useState([]);
  const { t } = useTranslation();

  const requirement = ['visible', 'add', 'edit', 'delete'];

  function checkRender() {
    let check = 'Role/Permission';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item?.permission_name == check
    );
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name.toLowerCase())
        ? setpermission((permissions) => [...permissions, items.permission_type_name.toLowerCase()])
        : false
    );
  }

  useEffect(() => {
    checkRender();
  }, []);
  const [list, setlist] = useState([]);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel, item) => () => {
    if (panel === expanded) {
      setExpanded(false);
      setroleDetails(null);
    } else {
      setroleDetails({
        name: item.role_name,
        id: item.id
      });
      setExpanded(panel);
    }
  };

  function getRoles() {
    setloadingTable(true);
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(api.getRoles, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success === true) {
          //  let decryptedata = JSON.parse(Decryption(result.data));
          setlist(result?.data);
        } else {
          console.log('no data found');
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setloadingTable(false);
      });
  }

  useEffect(() => {
    getRoles();
  }, []);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  const [adminModal, setadminModal] = useState(false);
  const handleAdminClose = () => setadminModal(false);
  const refreshAdmin = () => {
    setDeleteModal(false);
    setadminModal(false);
    getRoles();
  };
  //DELETE MANJAN
  const [DeleteModal, setDeleteModal] = React.useState(false);

  const handleClickOpenDeleteModal = () => {
    setDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModal(false);
  };

  const [roleDetails, setroleDetails] = useState(null);
  const [loading, setloading] = useState(false);

  function DeleteRole() {
    setloading(true);
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));

    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('id', Encryption(roleDetails.id, process.env.REACT_APP_SECRET_KEY));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.delete_roles, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        if (result.success == true) {
          refreshAdmin();
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => setloading(false));
  }
  const [loadingTable, setloadingTable] = useState(false);

  const [edit, setedit] = useState(false);

  if (permission.length > 0) {
    return (
      <Page title={`Role/Permissions | ${process.env.REACT_APP_PROJECTNAME}`}>
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
              {t('Role.name')}
            </Typography>
            {permission.includes('add') && (
              <Button
                onClick={() => {
                  setedit(false);
                  setadminModal(true);
                }}
                variant="contained"
                component={RouterLink}
                to="#"
                startIcon={<Iconify icon="eva:plus-fill" />}
              >
                {t('Role.add')}
              </Button>
            )}
          </Stack>
          {/* ROLE/PERMISSION Modal */}
          <Modal
            style={{
              overflow: 'scroll',
              height: '100%',
              display: 'block'
            }}
            open={adminModal}
            onClose={handleAdminClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <RoleModal
                handleClose={handleAdminClose}
                refresh={refreshAdmin}
                edit={edit}
                roleid={roleDetails}
              />
            </Box>
          </Modal>
          {/* ROLE/PERMISSION Modal End */}
          {/* Delete Modal */}
          <Dialog
            open={DeleteModal}
            onClose={handleCloseDeleteModal}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{t('Role.delete')}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {`${t('Role.alert.0')} ${roleDetails?.name} ${t('Role.alert.1')}`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <LoadingButton
                style={{ backgroundColor: 'red' }}
                size="medium"
                variant="contained"
                loading={loading}
                onClick={() => {
                  DeleteRole();
                }}
              >
                {t('Role.alert.2')}
              </LoadingButton>

              <Button variant="contained" onClick={handleCloseDeleteModal} autoFocus>
                {t('Role.alert.3')}
              </Button>
            </DialogActions>
          </Dialog>
          {/* Delete Moda End */}
          <Card>
            <Typography px={2} style={{ paddingTop: 15 }} variant="h5" gutterBottom>
              {t('Role.roles')}
            </Typography>
            <Scrollbar>
              {list.map((item, index) => {
                let expand = expanded === index ? true : false;
                return (
                  <Accordion
                    key={index}
                    expanded={expand}
                    onChange={handleChange(index, item)}
                    style={{
                      borderRadius: 0,
                      margin: 0,
                      borderTop: expand && '1px solid #919eab3d'
                    }}
                  >
                    <AccordionSummary
                      style={{ paddingTop: 5, paddingBottom: 5 }}
                      expandIcon={<Iconify icon="mdi:chevron-down" />}
                    >
                      <Typography
                        sx={{ width: '95%', flexShrink: 0, fontWeight: expand && 'bold' }}
                      >
                        {item.role_name} ({item.role_count})
                      </Typography>
                      {item.role_count == 0 && permission.includes('delete') && (
                        <IconButton
                          onClick={(event) => {
                            event.stopPropagation();
                            setroleDetails({
                              name: item.role_name,
                              id: item.id
                            });
                            handleClickOpenDeleteModal();
                          }}
                          edge="end"
                        >
                          <Iconify color="red" icon={'mdi:trash-can'} />
                        </IconButton>
                      )}
                    </AccordionSummary>
                    <AccordionDetails
                      style={{
                        backgroundColor: expand && 'rgba(253,208,151,0.2)',
                        borderTop: expand && '1px solid #919eab3d',
                        paddingTop: 30,
                        paddingBottom: 30
                      }}
                    >
                      <div
                        style={{
                          flexDirection: 'row',
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center'
                        }}
                      >
                        <Typography
                          sx={{
                            width: '33%',
                            flexShrink: 0,
                            fontWeight: expand && 'bold',
                            paddingTop: 0
                          }}
                        >
                          {t('Role.permission')}:
                        </Typography>
                        {permission.includes('edit') && (
                          <Button
                            onClick={() => {
                              setedit(true);
                              setadminModal(true);
                            }}
                            variant="contained"
                            component={RouterLink}
                            to="#"
                            endIcon={<Iconify icon="material-symbols:edit-note-rounded" />}
                          >
                            {t('Role.edit')}
                          </Button>
                        )}
                      </div>

                      {item?.permissions.map((item, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              paddingTop: 3,
                              paddingBottom: 3
                            }}
                          >
                            <Typography
                              sx={{
                                width: 170,
                                fontWeight: expand && 'bold'
                              }}
                            >
                              {item.permission_name}
                              {/* {t(item.translation)} */}
                            </Typography>
                            {item.permission_types.map((item, index) => {
                              return (
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    justifyContent: 'flex-start',
                                    width: 120
                                  }}
                                >
                                  {index == 0 && ':'}
                                  <Iconify
                                    icon="mdi:checkbox-outline"
                                    style={{ marginRight: 3, marginLeft: index == 0 && 3 }}
                                  />
                                  <Typography
                                    sx={{
                                      fontSize: 13
                                    }}
                                  >
                                    {item.permission_type_name}
                                    {/* {t(item.translation)} */}
                                  </Typography>
                                </div>
                              );
                            })}
                          </div>
                        );
                      })}
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </Scrollbar>
            {loadingTable && (
              <Box
                py={5}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  flex: 1
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Card>
        </Container>
      </Page>
    );
  } else {
    return null;
  }
}
