export const mainurl = process.env.REACT_APP_MAINURL;
export const chatUrl = process.env.REACT_APP_CHATURL;

export const api = {
  login: `${mainurl}loginwithrole`,
  register: `${mainurl}register`,
  getUsers: `${mainurl}getallusers`,
  update_profile: `${mainurl}update_profile`,
  get_users: `${mainurl}get_users`,
  get_employees: `${mainurl}get_employees`,
  get_payment_type_only: `${mainurl}get_payment_type_only`,
  get_payment_type: `${mainurl}get_payment_type`,
  get_deductions: `${mainurl}get_deductions`,
  get_invoice: `${mainurl}get_invoice`,
  invoice: `${mainurl}invoice`,
  invoice_pdf: `${mainurl}invoice_pdf`,
  delete_user: `${mainurl}delete_user`,
  get_agents: `${mainurl}get_realtor`,
  getCompany: `${mainurl}get_companies`,
  getTypes: `${mainurl}get_property_types`,
  getSaleType: `${mainurl}get_sale_type_method`,
  getConstructionStatus: `${mainurl}get_construction_status`,
  getAreaUnit: `${mainurl}get_area_unit`,
  getSortBy: `${mainurl}get_sortby`,
  UpdateUserStatus: `${mainurl}company_user_status_update`,
  getBusinesses: `${mainurl}get_property_external`,
  getOffers: `${mainurl}getbusinessoffers`,
  reset_password: `${mainurl}changePassword`,
  sendNotification: `${mainurl}sendnotification`,
  approveProperty: `${mainurl}approved_property`,
  approveRealtor: `${mainurl}approved_realtor`,
  approveOffers: `${mainurl}approve-businessoffers`,
  getbusinessoffers: `${mainurl}getbusinessoffers`,
  createAgent: `${mainurl}register`, //get-dashboard
  getDashboard: `${mainurl}get-dashboard`, //get-dashboard,
  getRequests: `${mainurl}get_inquiry`,
  getDocuments: `${mainurl}get_documents`,
  uploadDocument: `${mainurl}save_documents`,
  getHelp: `${mainurl}get-help`,
  saveHelp: `${mainurl}save-help`,
  getHelpCateogries: `${mainurl}get-categories`,
  getDocumentType: `${mainurl}get-document-type`,
  markasread: `${mainurl}editrequest`,
  getPosts: `${mainurl}get_posts`,
  postApprove: `${mainurl}update_posts_status`,
  sendEmail: `${mainurl}sendemail`,
  allseen: `${mainurl}all_seen`,
  //ROLE PERMISSIONS
  getRoles: `${mainurl}get_role_permission_and_types`,
  get_permissions: `${mainurl}get_permissions`,
  roles: `${mainurl}roles`,
  add_role_with_per_and_type: `${mainurl}add_role_with_per_and_type`,
  delete_roles: `${mainurl}delete_roles`,
  saveProfile: `${mainurl}allowed_realtors_edit`,
  updateProfile: `${mainurl}profile/update`,
  get_roles_names: `${mainurl}get_roles`,
  assignRole: `${mainurl}user_roles`,
  status: `${mainurl}status`,
  terminateadmin: `${mainurl}team_status_change`,
  resend_access: `${mainurl}resend_access`,
  admin_logout: `${mainurl}admin_logout`,
  user_roles: `${mainurl}user_roles`,
  //SideBar and Permission
  sideBar: `${mainurl}get_role_permission_by_user`,
  //team_status_change
  getDocumentType: `${mainurl}get-document-type`,
  // get-document-type
  //CITY AND STATES SEARCHABLE
  getcityandstates: `${mainurl}getcityandstates`,
  //Business Category
  getPropertyCategories: `${mainurl}get_categories`,
  // offer freq nd types
  getoffertype: `${mainurl}getoffertype`,
  getofferfrequency: `${mainurl}getofferfrequency`, //get-categories
  getUsersdocuments: `${mainurl}accept_user_list_documents`,
  getCategories: `${mainurl}get-categories`,
  get_event_logs: `${mainurl}get_event_logs`,
  getbusinessesbyid: `${mainurl}getbusinessesbyid`,
  getFeatures: `${mainurl}get_about`,
  delete_report_pictures: `${mainurl}delete_report_pictures`,
  user_terminate: `${mainurl}user_terminate`,
  property_realtor_change: `${mainurl}property_realtor_change`,
  save_property_history: `${mainurl}save_property_history`,
  company_realtor_edit: `${mainurl}company_realtor_edit`,
  get_property_types: `${mainurl}get_property_types`,
  get_construction_status: `${mainurl}get_construction_status`,
  upload_property_images: `${mainurl}upload_property_images`,
  save_properties: `${mainurl}save_properties`,
  upload_property_documents: `${mainurl}upload_property_documents`,
  get_company_contacts: `${mainurl}get_company_contacts`,
  save_company_contacts: `${mainurl}save_company_contacts`,
  delete_company_contacts: `${mainurl}delete_company_contacts`,
  delete_documents: `${mainurl}delete_documents`,
  save_property_comments: `${mainurl}save_property_comments`,
  get_property_comments: `${mainurl}get_property_comments`,
  delete_property_comments: `${mainurl}delete_property_comments`,
  allowed_realtors: `${mainurl}allowed_realtors_edit`,
  edit_property_documents: `${mainurl}edit_property_documents`,
  updateforgotpassword: `${mainurl}updateforgotpassword`,
  sendforgotpasswordweb: `${mainurl}sendforgotpasswordweb`,
  send_forgot_password: `${mainurl}sendforgotpassword`,
  check_code_validity: `${mainurl}check_code_validity`,
  import: `${mainurl}import`,
  save_view_logs: `${mainurl}save_view_logs`,
  get_view_logs: `${mainurl}get_view_logs`,
  update_status: `${mainurl}update_status`,
  getState: `${mainurl}get_states`,

  get_inventory: `${mainurl}get_inventory`,
  get_products: `${mainurl}get_products`,
  Saveinventory: `${mainurl}inventory`,
  getDeduction: `${mainurl}get_deductions`,
  addDeduction: `${mainurl}save_deductions`,
  DeleteDeduction: `${mainurl}delete_deductions`,
  getPaymentType: `${mainurl}get_payment_type`,
  savePaymentType: `${mainurl}save_payment_type`,
  DeletePaymentType: `${mainurl}delete_payment_type`,
  saveProducts: `${mainurl}save_products`,
  getProducts: `${mainurl}get_products`,
  deleteProducts: `${mainurl}delete_products`
};
