import * as React from 'react';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Box } from '@mui/material';

// components

import Page from 'src/components/Page';
import SettingTab1 from './SettingTab1';
import SettingTab2 from './SettingTab2';

// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function PaymentHistoryModal() {
  const [index, setindex] = useState(0);

  return (
    <Page title={`Employees | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Box ml={5} mb={5}>
        <Button
          style={{ marginRight: 10 }}
          onClick={() => {
            setindex(0);
          }}
          variant={index == 0 ? 'contained' : 'outlined'}
          component={RouterLink}
          to="#"
        >
          Payment Type
        </Button>

        <Button
          onClick={() => {
            setindex(1);
          }}
          variant={index == 1 ? 'contained' : 'outlined'}
          component={RouterLink}
          to="#"
        >
          Deductions
        </Button>
      </Box>
      {index == 0 ? <SettingTab1 /> : <SettingTab2 />}
    </Page>
  );
}
