import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// material

import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Box,
  Checkbox,
  Typography,
  Alert,
  MenuItem,
  Autocomplete
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { api } from 'src/Api';
import DropDown from 'src/layouts/dashboard/DropDown';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function ProductModal({ handleClose, refresh, data }) {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [checkBox, setcheckBox] = useState(false);
  const [checkerror, setcheckerror] = useState(false);
  const [loading, setloading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [products, setProducts] = useState([]);
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [selectedFile, setSelectedFile] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);

  const label = { inputProps: { 'aria-label': 'Master Admin Rights' } };

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required(t('Product name is required')),
    price: Yup.string().required(t('Product price is required')),
    description: Yup.string()
  });

  const formik = useFormik({
    initialValues: {
      name: data?.name || '',
      price: data?.price || 0,
      description: data?.description || ''
    },
    validationSchema: RegisterSchema,
    onSubmit: () => AddProduct()
  });

  useEffect(() => {
    if (checkBox === true) {
      setcheckerror(false);
    }
  }, [checkBox]);

  const AddProduct = () => {
    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();

    data && formdata.append('id', data?.id);

    formik.values.name &&
      formik.values.name != undefined &&
      formdata.append('name', Encryption(formik.values.name, process.env.REACT_APP_SECRET_KEY));
      formdata.append('price', Encryption(formik.values.price, process.env.REACT_APP_SECRET_KEY));

    formik.values.description &&
      formik.values.description != undefined &&
      formdata.append(
        'description',
        Encryption(formik.values.description, process.env.REACT_APP_SECRET_KEY)
      );

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);
    fetch(api.saveProducts, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        if (result.success === true) {
          console.log('Success Register');
          refresh();
        } else {
          setalert({ visible: true, message: result.message });
          console.log(result?.message, 'Error Register');
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error', error);
      })
      .finally(() => setloading(false));
  };

  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 20 }}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="string"
              label={'Name'}
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              type="number"
              label={'Price'}
              {...getFieldProps('price')}
              error={Boolean(touched.price && errors.price)}
              helperText={touched.price && errors.price}
            />
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <TextField
              fullWidth
              label={'Description (optional)'}
              type="string"
              multiline={true} // or multiline without the explicit 'true'
              rows={8}
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
           
          </Stack>
          {alert.visible && (
            <Alert
              onClose={() => {
                setalert({ visible: false, message: '' });
              }}
              severity="error"
              style={{ fontSize: 12, marginBottom: 20 }}
            >
              {Object.entries(alert?.message).map(([field, message]) => (
                <p key={field}>
                  <b>{field}:</b> {message}
                </p>
              ))}
            </Alert>
          )}
          {invoiceData && (
            <Typography variant="h1" gutterBottom>
              working Fine
              {/* Invoice Data: {JSON.stringify(invoiceData)} */}
            </Typography>
          )}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <LoadingButton
              sx={{ marginRight: 2 }}
              size="large"
              onClick={() => AddProduct()}
              variant="contained"
              loading={loading}
            >
              {t('AddAgent.buttons.1')}
            </LoadingButton>
            <Button onClick={handleClose} variant="outlined">
              {t('AddAgent.buttons.2')}
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
