import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useNavigate } from 'react-router-dom';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
// material

import {
  Stack,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Box,
  Checkbox,
  Typography,
  Alert,
  MenuItem,
  Autocomplete,
  debounce
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// component
import Iconify from '../../../components/Iconify';
import { api } from 'src/Api';
import DropDown from 'src/layouts/dashboard/DropDown';
import { Encryption } from 'src/functions/function';
import { useTranslation } from 'react-i18next';

// ----------------------------------------------------------------------

export default function InventoryAddModal({ handleClose, refresh, data }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [product_id, setpProduct_id] = useState(null);
  const [employee_id, setEmployee_id] = useState(null);
  const [product_price, setProduct_Price] = useState(null);
  const [quantity, setQuantity] = useState(false);
  const [type, setType] = useState(false);
  const [price, setPrice] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const [checkBox, setcheckBox] = useState(false);
  const [checkerror, setcheckerror] = useState(false);
  const [loading, setloading] = useState(false);
  const [roles, setRoles] = useState([]);
  const [products, setProducts] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [alert, setalert] = useState({ visible: false, message: '' });
  const [selectedFile, setSelectedFile] = useState(null);
  const [invoiceData, setInvoiceData] = useState(null);

  const label = { inputProps: { 'aria-label': 'Master Admin Rights' } };

  const RegisterSchema = Yup.object().shape({
    product: Yup.string().required(t('Product required')),
    employee: Yup.string().required(t('Employee required')),
    quantity: Yup.number().required(t('Quantity required')),
    price: Yup.number().required(t('Price required')),
    type: Yup.string().required(t('Type required'))
  });

  const formik = useFormik({
    initialValues: {
      firstName: data?.firstname,
      lastName: data?.lastname,
      email: data?.email,
      username: data?.username,
      password: '',
      confirmpassword: '',
      role: data?.role || '',
      position: data?.position,
      dateHired: data?.dateHired || '',
      dateResigned: data?.dateResigned || '',
      attachment: data?.attachment
    },
    validationSchema: RegisterSchema,
    onSubmit: () => AddInventory()
  });

  useEffect(() => {
    GetProducts();
  }, []);

  useEffect(() => {
    GetEmployees();
  }, []);

  useEffect(() => {
    if (checkBox === true) {
      setcheckerror(false);
    }
  }, [checkBox]);

  const AddInventory = () => {
    var myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    const user = JSON.parse(localStorage.getItem('user'));
    myHeaders.append('Authorization', 'Bearer ' + token);

    var formdata = new FormData();
    product_id &&
      product_id != undefined &&
      formdata.append('product_id', Encryption(product_id, process.env.REACT_APP_SECRET_KEY));
    
    employee_id &&
      employee_id != undefined &&
      formdata.append('employee_id', Encryption(employee_id, process.env.REACT_APP_SECRET_KEY));

    formik.values.quantity &&
      formik.values.quantity != undefined &&
      formdata.append(
        'quantity',
        Encryption(formik.values.quantity, process.env.REACT_APP_SECRET_KEY)
      );

    formik.values.type &&
      formik.values.type != undefined &&
      formdata.append('type', Encryption(formik.values.type, process.env.REACT_APP_SECRET_KEY));


    formdata.append('price', Encryption(product_price, 0));

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    setloading(true);
    fetch(api.Saveinventory, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        setloading(false);
        const result = JSON.parse(result_);
        if (result.success === true) {
          console.log('Success Register');
          refresh();
        } else {
          setalert({ visible: true, message: result.message });
          console.log(result?.message, 'Error Register');
        }
      })
      .catch((error) => {
        setloading(false);
        console.log('error', error);
      })
      .finally(() => setloading(false));
  };

  const fetchProducts = debounce(
    (
      api,
      requestOptions,
    ) => {
      fetch(api, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            setProducts(result.data)
            console.log('API call successful:', api, result.data);
          } else {
            console.error('API call failed:', result.error);
          }
        })
        .catch((error) => {
          console.error('Fetch error:', error);
        });
    },
    400,
  )

  function GetProducts(searchProduct) {
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);
    let formdata = new FormData();
    
    searchProduct?.length > 0 && 
      formdata.append('search', Encryption(searchProduct, process.env.REACT_APP_SECRET_APP));

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetchProducts(api.get_products, requestOptions)
  }

  const fetchEmployees = debounce(
    (
      api,
      requestOptions
    ) => {
      fetch(api, requestOptions)
        .then((response) => response.json())
        .then((result) => {
          if (result.success === true) {
            setEmployees(result.data)
            console.log('API call successful:', api, result.data);
          } else {
            console.error('API call failed:', result.error);
          }
        })
        .catch((error) => {
          console.error('Fetch error:', error);
        });
    },
    400,
  )

  function GetEmployees(params) {
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));
    myHeaders.append('Authorization', `Bearer ${token}`);
    let formdata = new FormData();
    const payment_code = 'inventory';

    formdata.append('payment_code', Encryption(payment_code, process.env.REACT_APP_SECRET_APP));

    let requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetchEmployees(api.get_employees, requestOptions)

  }
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 20 }}>
        <Stack spacing={3}>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            <Autocomplete
              fullWidth
              disablePortal
              id="product-list"
              {...getFieldProps('product')}
              SelectProps={{ native: false }}
              error={Boolean(touched.product && errors.product)}
              helperText={touched.product && errors.product}
              options={[{ name: 'Select Product' }, ...(products || [])]}
              getOptionLabel={(product) => product?.name}
              onChange={(e, newValue) => {
                setpProduct_id(newValue?.id);
              }}
              onInputChange={(event, newInputValue) => {
                console.log({ newInputValue })
                GetProducts(newInputValue);
              }}
              
              renderInput={(params) => 
                <TextField 
                  {...params} 
                  label="Select Product" />
              }
            />
            
            <Autocomplete
              fullWidth
              disablePortal
              id="employee-list"
              {...getFieldProps('employee')}
              SelectProps={{ native: false }}
              error={Boolean(touched.employee && errors.employee)}
              helperText={touched.employee && errors.employee}
              options={[{ fullname: 'Select Employee' }, ...(employees || [])]}
              getOptionLabel={(employee) => employee.fullname}
              onChange={(e, newValue) => {
                setEmployee_id(newValue?.id);
              }}
              renderInput={(params) => <TextField {...params} label="Select Employee" />}
            />
            
          </Stack>

          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
            
            <TextField
              fullWidth
              select
              label={t('Select Inventory IN + OUT')}
              {...getFieldProps('type')}
              SelectProps={{ native: false }}
              error={Boolean(touched.type && errors.type)}
              helperText={touched.type && errors.type}
            >
              <MenuItem>{t('Select Type')}</MenuItem>
              <MenuItem value="in">{t('In')}</MenuItem>
              <MenuItem value="out">{t('Out')}</MenuItem>
            </TextField>

            <TextField
              fullWidth
              type="number"
              label={'Quantity'}
              {...getFieldProps('quantity')}
              error={Boolean(touched.quantity && errors.quantity)}
              helperText={touched.quantity && errors.quantity}
            />
          </Stack>

          
          {alert.visible && (
            <Alert
              onClose={() => {
                setalert({ visible: false, message: '' });
              }}
              severity="error"
              style={{ fontSize: 12, marginBottom: 20 }}
            >
              {alert?.message}
            </Alert>
          )}
          {invoiceData && (
            <Typography variant="h1" gutterBottom>
              working Fine
              {/* Invoice Data: {JSON.stringify(invoiceData)} */}
            </Typography>
          )}
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              width: '100%'
            }}
          >
            <LoadingButton
              sx={{ marginRight: 2 }}
              size="large"
              onClick={() => AddInventory()}
              variant="contained"
              loading={loading}
            >
              {t('AddAgent.buttons.1')}
            </LoadingButton>
            <Button onClick={handleClose} variant="outlined">
              {t('AddAgent.buttons.2')}
            </Button>
          </Box>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
