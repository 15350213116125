import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <RouterLink to="#">
      <Box
        component="img"
        src={process.env?.REACT_APP_SIDEBAR_LOGO}
        sx={{ marginLeft: 1, width: 150, height: 50, marginTop: 1, ...sx }}
      />
    </RouterLink>
  );
}
