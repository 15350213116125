import * as React from 'react';
import Box from '@mui/material/Box';
import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { filter } from 'lodash';
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  Modal,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Pagination
} from '@mui/material';

// components
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import Searchbar from 'src/layouts/dashboard/Searchbar';
import DropDown from 'src/layouts/dashboard/DropDown';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';

import {
  UserListHead,
  AdminModal,
  AdminEditModal,
  AdminDetailModal,
  TerminateModal
} from '../sections/@dashboard/user';
import pallete from '../theme/palette';
import { api } from '../Api';
import { Decryption, Encryption } from 'src/functions/function';
import { chatUrl } from '../Api';
import ListModal from 'src/sections/@dashboard/documents/listModal';
import InvoiceList from 'src/sections/@dashboard/documents/InvoiceList';
import PayslipModal from 'src/sections/@dashboard/documents/payslipModal';
import PaymentHistoryShowModal from 'src/sections/@dashboard/documents/paymentHistoryModal';
// ----------------------------------------------------------------------

// ----------------------------------------------------------------------

export default function Agents() {
  const self = JSON.parse(localStorage.getItem('user'));

  const [TABLE_HEAD, setTABLE_HEAD] = useState([
    { id: 'firstname', label: 'Name', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false },
    { id: 'role', label: 'Role', alignRight: false },
    { id: 'position', label: 'Position', alignRight: false },
    { id: 'date_hired', label: 'Date Hired', alignRight: false },
    { id: 'date_resigned', label: 'Date Resigned', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false }
  ]);

  const [permission, setpermission] = useState([]);
  const [showGridActons, setShowGridActons] = useState(false);
  const requirement = [
    'View',
    'Add',
    'Edit',
    'Delete',
    'Generate',
    'Payment History',
    'Change Role'
  ];

  function checkRender() {
    let check = 'Employees';
    const localPerm = JSON.parse(localStorage.getItem('permissions'))?.filter(
      (item) => item.permission_name == check
    );
    console.log(localPerm);
    localPerm[0]?.permission_types?.filter((items) =>
      requirement.includes(items.permission_type_name)
        ? setpermission((permissions) => [...permissions, items.permission_type_name])
        : false
    );

    ////////////////////  for grid actons ////////////////////

    const requirementGrid = ['Edit', 'Delete', 'Generate', 'Payment History'];

    const checkGridActions = localPerm[0]?.permission_types?.filter((items) =>
      requirementGrid.includes(items.permission_type_name) ? true : false
    );

    if (checkGridActions && checkGridActions.length > 0) {
      setShowGridActons(true);
      setTABLE_HEAD((prevState) => [
        ...prevState,
        { id: 'actions', label: 'Actions', alignCenter: true }
      ]);
    } else {
      setShowGridActons(false);
    }

    ////////////////////////////////////////////////////////////
  }

  const [list, setlist] = useState([]);
  const [search, setsearch] = useState('');
  const [invoicesList, setInvoicesList] = useState([]);

  function getUsers(page) {
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    // console.log(token);
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_APP));
    user?.role != 3 &&
      formdata.append('company_id', Encryption(user?.id, process.env.REACT_APP_SECRET_APP));
    search?.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));
    // filterRole?.length > 0 && filterRole != 'All' && formdata.append('role_filter', filterRole);
    // status?.length > 0 && status != 'All' && formdata.append('status_filter', status);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_users, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        console.log(result, 'result_');
        if (result.success === true) {
          list.length = 0;
          // let decryptedData = JSON.stringify(result.data);
          console.log(result.data, 'result data');
          setlist(result.data);
          handleClose();
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
        setloadingTable(false);
      });
  }

  function getUserInvoices(page) {
    const myHeaders = new Headers();
    const user = JSON.parse(localStorage.getItem('user'));
    const token = JSON.parse(localStorage.getItem('token'));
    // console.log(token);
    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    page && formdata.append('page', Encryption(page, process.env.REACT_APP_SECRET_APP));
    user?.role != 3 &&
      formdata.append('company_id', Encryption(user?.id, process.env.REACT_APP_SECRET_APP));
    search?.length > 0 &&
      formdata.append('search', Encryption(search, process.env.REACT_APP_SECRET_APP));
    // filterRole?.length > 0 && filterRole != 'All' && formdata.append('role_filter', filterRole);
    // status?.length > 0 && status != 'All' && formdata.append('status_filter', status);

    const requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    fetch(api.get_invoice, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        const result = JSON.parse(result_);
        console.log(result, 'result_m getInvoices');
        if (result.success === true) {
          // list.length = 0;
          // let decryptedData = JSON.stringify(result.data);
          console.log(result.data, 'result data getInvoices');
          // setlist(result.data);
          handleClose();
          result?.pagination_count
            ? setpagesCount(Math.ceil(result?.pagination_count / 10))
            : setpagesCount(1);
        } else {
          setlist([]);
          setpagesCount(0);
        }
      })
      .catch((error) => console.log(error, 'errorrrrrr'))
      .finally(() => {
        setloading(false);
        setloadingTable(false);
      });
  }

  useEffect(() => {
    setloading(true);
    checkRender();
    getUsers();
  }, []);

  const handleClose = () => setOpen(false);

  const [open, setOpen] = React.useState(false);
  const [user, setuser] = useState(null);

  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('');

  const isUserNotFound = list.length === 0;
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '70%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4
  };

  const [adminModal, setadminModal] = useState(false);
  const handleAdminClose = () => setadminModal(false);

  const [adminEditModal, setEditadminModal] = useState(false);
  const handleEditAdminClose = () => {
    // setEditadminModal(!adminEditModal);
    setadminModal(!adminModal);
  };

  const [PaymentHistoryModal, setPaymentHistoryModal] = useState(false);
  const handlePaymentHistoryModalClose = () => setPaymentHistoryModal(false);

  const [listInvoiceModal, setListInvoiceModal] = useState(false);
  const handleInvoiceListClose = () => {
    // setEditadminModal(!adminEditModal);
    setListInvoiceModal(!listInvoiceModal);
  };

  const refreshAdmin = () => {
    setadminModal(false);
    setEditadminModal(false);
    setterminate(false);
    getUsers(page);
    setuser(null);
  };

  const [loading, setloading] = useState(false);
  const [loadingTable, setloadingTable] = useState(false);
  const [terminate, setterminate] = useState(false);

  const handleTerminateClose = () => setterminate(false);

  const [selectedIndex, setselectedIndex] = useState(null);
  const [deleting, setdeleting] = useState(false);

  function ActionRealtor(id, status, index) {
    setselectedIndex(index);
    setdeleting(true);
    const token = JSON.parse(localStorage.getItem('token'));
    const myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${token}`);
    var formdata = new FormData();
    formdata.append('realtor_id', Encryption(id, process.env.REACT_APP_SECRET_APP));
    formdata.append('status', Encryption(status, process.env.REACT_APP_SECRET_APP));
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };
    console.log('before deleting');
    fetch(`${api.delete_user}?id=${id}`, requestOptions)
      .then((response) => response.text())
      .then((result_) => {
        console.log(result_, 'result_ delete');
        const result = JSON.parse(result_);
        console.log(result, 'result json parse delete');
        if (result.success === true) {
          // window.alert(id, 'deleted');
          console.log('Successfully deleted');
          getUsers(page);
        }
      })
      .catch((error) => {
        console.log('error', error, 'Catch deleted error');
      })
      .finally(() => {
        setdeleting(false);
        setselectedIndex(false);
      });
    console.log('after deleting');
  }
  // console.log('after function deleting');
  const [ReasonModal, setReasonModal] = useState(false);
  const [reason, setreason] = useState('');
  const [changeRole, setChangeRole] = useState(false);
  const [currentRole, setcurrentRole] = useState('');
  const [updatedRole, setupdatedRole] = useState(null);
  const [roleLoader, setroleLoader] = useState(false);

  function ChangeRole() {
    setroleLoader(true);
    const myHeaders = new Headers();
    const token = JSON.parse(localStorage.getItem('token'));

    myHeaders.append('Authorization', `Bearer ${token}`);

    var formdata = new FormData();
    formdata.append('user_id', Encryption(user?.id, process.env.REACT_APP_SECRET_APP));

    formdata.append(
      'role_id',
      Encryption(JSON.parse(updatedRole)?.id, process.env.REACT_APP_SECRET_APP)
    );

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow'
    };

    fetch(api.user_roles, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.success == true) {
          setupdatedRole(null);
          setChangeRole(false);
          setuser(null);
          setcurrentRole('');
          getUsers();
        }
      })
      .catch((error) => console.log('error', error))
      .finally(() => {
        setroleLoader(false);
      });
  }

  const [pagesCount, setpagesCount] = useState(1);
  const [page, setPage] = React.useState(1);
  const handlePageChange = (event, value) => {
    list.length = 0;
    setPage(value);
    setloading(true);
    getUsers(value);
  };

  const handleKeyPress = (event) => {
    console.log(event.key);
    if (event.key === 'Enter') {
      setloadingTable(true);
      getUsers();
    }
  };

  const invoiceStyle = {
    width: '80%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    // p: 4,
    border: '2px solid',
    borderRadius: 2,
    borderColor: pallete.primary.main,
    overflow: 'hidden'
  };

  const boxStyle = {
    minWidth: '70%',
    maxHeight: '90%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'auto',
    // p: 4,
    border: '2px solid',
    borderColor: pallete.primary.main
  };

  return (
    <Page title={`Employees | ${process.env.REACT_APP_PROJECTNAME}`}>
      <Container>
        {/* DETAIL MODAL */}
        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <AdminDetailModal handleClose={handleClose} details={user} />
          </Box>
        </Modal>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Employees
          </Typography>

          {permission.includes('Add') && (
            <Button
              onClick={() => {
                console.log('it works!');
                setuser(null);
                setadminModal(true);
              }}
              variant="contained"
              component={RouterLink}
              to="#"
              startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add Employee
              {/* Add User */}
            </Button>
          )}
        </Stack>
        {/* AGENT MODAL */}
        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={adminModal}
          onClose={handleAdminClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="admin_modal" borderRadius={2}>
            <AdminModal handleClose={handleAdminClose} refresh={refreshAdmin} data={user} />
          </Box>
        </Modal>

        {/* ADMIN Edit MODAL */}
        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={adminEditModal}
          onClose={handleEditAdminClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="admin_modal">
            <AdminEditModal data={user} handleClose={handleEditAdminClose} refresh={refreshAdmin} />
          </Box>
        </Modal>

        {/* TERMINATE MODAL */}
        <Modal
          style={{
            overflow: 'scroll',
            height: '100%',
            display: 'block'
          }}
          open={terminate}
          onClose={handleAdminClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <TerminateModal handleClose={handleTerminateClose} refresh={refreshAdmin} user={user} />
          </Box>
        </Modal>

        {/* Invoice List Modal */}
        {/* GERATE PAY SLIP MODAL*/}
        <Modal
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          open={listInvoiceModal}
          onClose={handleInvoiceListClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, invoiceStyle }} borderRadius={2} style={{ width: '75%' }}>
            <Box>
              <PayslipModal
                handleClose={handleInvoiceListClose}
                // userInvoices={userInvoices}
                refresh={refreshAdmin}
                data={user}
                // userid={user?.id}
              />
            </Box>
            <Box>{/* <Typography>hello</Typography> */}</Box>
          </Box>
        </Modal>
        {/* HISTORY MODAL */}
        <Modal
          style={{
            display: 'flex',
            height: '100%',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          open={PaymentHistoryModal}
          onClose={handlePaymentHistoryModalClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...boxStyle, invoiceStyle }} borderRadius={2} style={{ width: '75%' }}>
            <Box>
              <PaymentHistoryShowModal
                handleClose={handlePaymentHistoryModalClose}
                refresh={refreshAdmin}
                data={user}
                // userid={user?.id}
              />
            </Box>
            <Box>{/* <Typography>hello</Typography> */}</Box>
          </Box>
        </Modal>
        {/* DIALOG REASON */}
        <Dialog
          open={ReasonModal}
          onClose={() => setReasonModal(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{'Termination Reason'}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{reason}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setReasonModal(false)} autoFocus>
              Okay
            </Button>
          </DialogActions>
        </Dialog>
        {/* CHANGE ROLE */}
        <Dialog
          open={changeRole}
          onClose={() => setChangeRole(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle variant="h5" id="alert-dialog-title">
            {'Change Role'}
          </DialogTitle>
          <DialogContent style={{ width: 500 }}>
            <Box style={{ marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
              <Typography>Current Role: &nbsp;</Typography>
              <Typography style={{ marginBottom: 10, fontWeight: 'bold' }}>
                {' '}
                {currentRole && JSON.parse(currentRole)?.role_name}
              </Typography>
            </Box>
            <DropDown
              setRole={(val) => {
                setupdatedRole(JSON.stringify(val));
              }}
              fullWidth
              dontAppend
            />
          </DialogContent>
          <DialogActions>
            {updatedRole && (
              <LoadingButton
                disabled={JSON.parse(updatedRole).id != JSON.parse(currentRole).id ? false : true}
                variant="contained"
                loading={roleLoader}
                onClick={() => ChangeRole()}
              >
                Update
              </LoadingButton>
            )}
            <Button variant="contained" onClick={() => setChangeRole(false)} autoFocus>
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Card>
          <Box
            p={2}
            style={{
              flexDirection: 'row',
              display: 'flex',
              flex: 1
            }}
          >
            <Searchbar
              placeholder="Search Employees..."
              width={'95%'}
              value={search}
              setFunction={(val) => {
                setsearch(val);
              }}
              handleKeyPress={handleKeyPress}
            />

            <LoadingButton
              style={{ width: '5%', marginLeft: '2.5%' }}
              variant="contained"
              loading={loadingTable}
              onClick={() => {
                setloadingTable(true);
                getUsers();
              }}
            >
              <Iconify icon="material-symbols:search" sx={{ width: 25, height: 25 }} />
            </LoadingButton>
          </Box>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={list.length}
                  numSelected={selected.length}
                />
                <TableBody>
                  {list.map((row, index) => {
                    const {
                      id,
                      firstname,
                      lastname,
                      avatar,
                      email,
                      seen,
                      status,
                      contact,
                      username,
                      position,
                      date_hired,
                      date_resigned,
                      role
                    } = row;
                    // console.log(role, 'role');
                    const isItemSelected = selected.indexOf(firstname) !== -1;

                    return (
                      <TableRow
                        hover
                        style={{
                          backgroundColor: seen == '0' && 'rgba(253,208,151,0.2)',
                          cursor: seen == '0' ? 'pointer' : 'default',
                          height: 100
                        }}
                        key={id}
                        tabIndex={-1}
                        role="checkbox"
                        selected={isItemSelected}
                        aria-checked={isItemSelected}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          padding="normal"
                          style={{ maxWidth: 180 }}
                        >
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                            style={{ maxWidth: 250 }}
                          >
                            <Avatar alt={firstname} src={avatar + '?v=' + Date.now()} />
                            <Box style={{ display: 'flex', width: '90%' }}>
                              <Typography variant="subtitle2">
                                {firstname} {lastname}
                              </Typography>
                            </Box>
                          </Stack>
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 250 }}>
                          <Typography noWrap>{email}</Typography>
                          <Typography noWrap>@{username}</Typography>
                        </TableCell>
                        <TableCell key={role?.id} align="left" style={{ maxWidth: 100 }}>
                          {role?.role_name ? role?.role_name : 'N/A'}
                        </TableCell>

                        <TableCell align="left" style={{ maxWidth: 20 }}>
                          {position ? position : 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          {date_hired ? date_hired : 'N/A'}
                        </TableCell>
                        <TableCell align="left" style={{ maxWidth: 100 }}>
                          {date_resigned ? date_resigned : 'N/A'}
                        </TableCell>
                        <TableCell align="left">
                          <Label
                            style={{ height: 30, paddingLeft: 30, paddingRight: 30, width: 100 }}
                            variant="ghost"
                            color={
                              (status === 'active' && 'success') ||
                              (status === 'terminated' && 'error') ||
                              'error'
                            }
                          >
                            <Typography
                              style={{
                                textTransform: 'capitalize',
                                fontWeight: 'bold',
                                fontSize: 12
                              }}
                            >
                              {status}
                            </Typography>
                          </Label>
                        </TableCell>
                        {showGridActons && (
                          <>
                        <TableCell
                          align="left"
                          style={{
                            flexDirection: 'column',
                            minWidth: 150
                          }}
                        >
                          <Stack
                            // spacing={2}
                            m={0}
                            p={0}
                            style={{
                              flexDirection: 'row',
                              display: 'flex',
                              width: '100%',
                              justifyContent: 'space-between'
                            }}
                          >
                            {permission.includes('Delete') && (
                              <>
                                <Box>
                                  {status == 'active' && (
                                    <LoadingButton
                                      startIcon={
                                        <Iconify
                                          color={pallete.primary.main}
                                          icon="ic:baseline-delete"
                                        />
                                      }
                                      style={{ marginTop: 5, width: 87 }}
                                      variant="outlined"
                                      loading={selectedIndex == index && deleting}
                                      onClick={(event) => {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        ActionRealtor(id, 'deleted', index);
                                      }}
                                    >
                                      Delete
                                    </LoadingButton>
                                  )}
                                </Box>
                              </>
                            )}

                            {permission.includes('Edit') && (
                              <>
                                <Box>
                                  <Button
                                    onClick={(event) => {
                                      event.preventDefault();
                                      event.stopPropagation();
                                      setuser(row);
                                      handleEditAdminClose();
                                    }}
                                    style={{ marginTop: 5, width: 87 }}
                                    variant="outlined"
                                    component={RouterLink}
                                    to="#"
                                    startIcon={
                                      <Iconify color={pallete.primary.main} icon="tabler:edit" />
                                    }
                                  >
                                    Edit
                                  </Button>
                                </Box>
                              </>
                            )}
                          </Stack>
                          {permission.includes('Generate') && (
                            <>
                              <Box m={0} p={0} style={{ flexDirection: 'column', display: 'flex' }}>
                                <Button
                                  onClick={(event) => {
                                    setuser(row);
                                    handleInvoiceListClose();
                                  }}
                                  style={{ marginTop: 5, width: 178 }}
                                  variant="outlined"
                                  component={RouterLink}
                                  to="#"
                                  startIcon={
                                    <Iconify
                                      color={pallete.primary.main}
                                      icon="ic:twotone-history"
                                    />
                                  }
                                >
                                  Generate Slip
                                </Button>
                              </Box>
                            </>
                          )}
                          {permission.includes('Payment History') && (
                            <>
                              <Box m={0} p={0} style={{ flexDirection: 'column', display: 'flex' }}>
                                <Button
                                  onClick={(event) => {
                                    setuser(row);
                                    setPaymentHistoryModal(true);
                                  }}
                                  style={{ marginTop: 5, width: 178 }}
                                  variant="outlined"
                                  component={RouterLink}
                                  to="#"
                                  startIcon={
                                    <Iconify
                                      color={pallete.primary.main}
                                      icon="fluent:payment-20-filled"
                                    />
                                  }
                                >
                                  Payments History
                                </Button>
                              </Box>
                            </>
                          )}
                        </TableCell>
                        </>
                        )}
                      </TableRow>
                    );
                  })}
                </TableBody>
                {isUserNotFound && !loading && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={search} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
              {loading && (
                <Box
                  py={5}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flex: 1
                  }}
                >
                  <CircularProgress />
                </Box>
              )}
            </TableContainer>
          </Scrollbar>
        </Card>
        {!loading && !loadingTable && pagesCount > 1 && (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: 10,
              alignItems: 'center'
            }}
          >
            <Pagination
              count={pagesCount}
              color="primary"
              variant="outlined"
              shape="rounded"
              onChange={handlePageChange}
              page={page}
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
